/* Custom Material Element */

mat-form-field {
  width: 100% !important;
}

.mat-sidenav-container {
  background-color: #fff;
  background: linear-gradient(0deg, #f0f2f5 50%, #ffffff 50%) !important;
  width: 100%;
  height: 100%;
}

.mat-sidenav-container.sidenav-container-mobile {
  background: #f0f2f5 !important;
}

.mat-toolbar {
  position: relative !important;
  z-index: 99 !important;
}

.mat-sidenav-container.sidenav-container-mobile {
  .mat-toolbar {
    margin-left: -10px !important;
  }
}

.toobar-spacer {
  flex: 1 1 auto;
}

.mat-drawer {
  width: 300px !important;
  border-right: none !important;
  // background-color: #1e7292 !important;
  background: linear-gradient(to bottom, #03a9f4 0, #1a7ac7 100%) !important;
}

.mat-drawer-mobile {
  background: #f5f5f5 !important;
}

@media (max-width: 700px) {
  .mat-drawer-content {
    padding: 0 10px !important;
  }
}

// ScrollBar Nav Bar Firefox
.mat-drawer-inner-container {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #6c9caf #1e7292; /* scroll thumb and track */
}

// ScrollBar Nav Bar Chrome, Edge y Safari
.mat-drawer-inner-container::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.mat-drawer-inner-container::-webkit-scrollbar-track {
  background: #f0f2f5 !important; /* color of the tracking area */
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background-color: #c2c2c2; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 2px solid #f0f2f5 !important; /* creates padding around scroll thumb */
}

// ScrollBar all documents
* {
  scrollbar-width: thin;
  scrollbar-color: #b0b4ba #f0f2f5;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f2f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #b0b4ba;
  border-radius: 20px;
  border: 2px solid #f0f2f5;
}

.mat-elevation-z4 {
  /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
  box-shadow: 0 8px 26px -4px rgb(20 20 20 / 15%), 0 8px 9px -5px rgb(20 20 20 / 6%) !important;
}

.mat-toolbar.mat-primary {
  position: fixed !important;
  color: #4a4a4a;
  background: #f0f2f5 !important;
  transition: box-shadow 0.25s ease-in, background-color 0.25s ease-in;
  -webkit-backdrop-filter: saturate(200%) blur(20px);
  backdrop-filter: saturate(50%) blur(20px);
  background-color: rgba(255, 255, 255, 0.6) !important;
  border-bottom: 1px solid #efefef;
}

.mat-list-base a.mat-list-item {
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  //text-shadow: 1px 1px 3px #ffffff !important;
}

.mat-list-base a.mat-list-item fa-icon {
  width: 32px !important;
  font-size: 20px !important;
  display: block;
}

.mat-list-base a.mat-list-item.active {
  color: rgb(28 183 220) !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 55px !important;
  padding: 0 !important;
}

.mat-toolbar.footer {
  margin: 0 !important;
  background-color: #f0f2f5 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0 0 0 10px !important;
  border-top: dashed 1px #c0c0c0 !important;
}

.mat-toolbar.footer span.title {
  margin-left: 70px;
}

.mat-toolbar.footer a {
  color: #000000 !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}

.mat-mdc-card {
  -webkit-box-shadow: 0 5px 30px 0 rgb(0 0 0 / 3%) !important;
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 3%) !important;
  min-height: calc(100vh - 510px) !important;
  margin-bottom: 50px !important;
}

.mat-button-toggle-checked {
  background-color: #dde2eb !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 40px !important;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 3%) !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: 48px !important;
}

@media (max-width: 1024px) {
  .mat-mdc-card {
    min-height: calc(100vh - 410px) !important;
  }
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #3880ff !important;
}

.mat-flat-button.mat-info,
.mat-raised-button.mat-info,
.mat-fab.mat-info,
.mat-mini-fab.mat-info {
  background-color: #3dc2ff !important;
}

.mat-flat-button.mat-info,
.mat-raised-button.mat-info,
.mat-fab.mat-info,
.mat-mini-fab.mat-info {
  color: #fff !important;
}

.mat-button.mat-orange,
.mat-icon-button.mat-orange,
.mat-stroked-button.mat-orange {
  color: #ff9800;
}

.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

.mat-radio-button {
  margin: 0 12px 0 0 !important;
}

.mat-step-header {
  border-radius: 10px !important;
}

.mat-mdc-option {
  min-height: 40px !important;
  font-size: 14px !important;
}

.mat-mdc-form-field {
  line-height: 2 !important;
  font-size: 14px !important;
}

.mat-mdc-form-field-infix {
  min-height: 50px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 13px !important;
  padding-bottom: 10px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 22px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-28.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;
}

.mdc-text-field--outlined .mdc-floating-label {
  font-size: 14px !important;
}

.mat-mdc-select {
  font-size: 14px !important;
}

.mat-mdc-menu-panel {
  max-width: 350px !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 14px !important;
}
