.w100 {
  width: 100% !important;
}

.w90 {
  width: 90% !important;
}

.w80 {
  width: 80% !important;
}

.w50 {
  width: 50% !important;
}

.w60 {
  width: 60% !important;
}

.w70 {
  width: 70% !important;
}

.w100px {
  width: 100px !important;
}

.w150px {
  width: 150px !important;
}

.h50 {
  height: 50px !important;
}

.h42 {
  height: 42px !important;
}

.h40 {
  height: 40px !important;
}

.h30 {
  height: 30px !important;
}

.h10 {
  height: 10px !important;
}

.float-right {
  float: right !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.p10 {
  padding: 10px !important;
}

.contentCenterButtons {
  text-align: center;
}

.position-absolute {
  position: absolute !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.p-form-details {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.content-titleSection {
  width: 100% !important;
  padding: 5px 15px 7px 82px !important;

  .titleSection {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    padding: 10px 0 10px 0px !important;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 15px !important;
    margin-top: 19px !important;
    display: flex !important;
    box-sizing: border-box !important;
    flex-direction: row !important;
    align-items: center !important;
    max-width: 3000px !important;
    margin: 0 auto !important;

    h2 {
      font-size: 24px !important;
      font-weight: 300 !important;
      margin-bottom: 0 !important;
    }

    .box {
      background: #08bade;
      width: 55px;
      height: 55px;
      float: left;
      margin-right: 20px;
      box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
        0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
      border-radius: 0.25rem;
      text-align: center;
    }

    .mat-fab,
    .mat-mini-fab {
      box-shadow: none !important;
    }

    .contentTitle {
      width: 100%;

      .fa-icons {
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
      }

      .material-icons,
      .fa-icons {
        color: white !important;
      }

      .material-icons {
        margin: 16% auto !important;
        font-size: 35px !important;
      }

      .fa-icons {
        margin: 18% auto !important;
        font-size: 30px !important;
      }
    }

    button {
      margin-right: 15px !important;
    }
  }
}

.content-router-outlet-mobile {
  .content-titleSection {
    padding: 10px 10px 7px 10px !important;
    background: white;

    .titleSection {
      padding: 0px !important;
      margin-bottom: 5px !important;
      margin-top: 10px !important;
      display: block !important;
      margin: 0 auto !important;

      h2 {
        font-size: 15px !important;
      }

      .box {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      .contentTitle {
        .material-icons {
          margin: 16% auto !important;
          font-size: 25px !important;
        }

        .fa-icons {
          margin: 18% auto !important;
          font-size: 30px !important;
        }
      }

      button {
        margin-right: 15px !important;
      }
    }
  }
}

span.titleExpansionPanel {
  margin: 2px 0 0 5px !important;
  font-size: 16px;
  font-weight: 500;
  color: #607d8b;
}

.content-data-box {
  background-color: #f0f2f5 !important;
  padding: 20px 20px 0px 81px !important;
  .data-box {
    min-height: calc(100vh - 220px) !important;
    max-width: 3000px !important;
    margin: 0 auto !important;
  }
}

.content-router-outlet-mobile {
  .content-data-box {
    padding: 20px 0px 0px 0px !important;
  }
}

.modal-title {
  font-size: 18px !important;
  text-align: center !important;
  margin-top: 3px !important;
  margin-bottom: 0px !important;
  font-weight: 400 !important;
  color: #3f4144 !important;
}

.modal-header {
  padding: 5px 15px !important;
}

.modal-header {
  .iconHeader {
    color: #999 !important;
    font-size: 25px !important;
    height: 25px !important;
    width: 25px !important;
  }
}

.modal-footer {
  display: block !important;
}

.swal2-styled.swal2-confirm {
  background-color: #2196f3 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px #aad8fd !important;
}

.swal2-html-container {
  font-size: 15px !important;
  margin: 0 !important;
  color: #6c757d !important;
  padding: 0 5px !important;
}

.swal2-textarea {
  font-size: 14px !important;
}

.swal2-validation-message {
  font-size: 14px !important;
}

ag-grid-angular {
  button .material-icons {
    font-size: 20px !important;
  }

  button.mdc-icon-button {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 5px !important;
  }

  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    position: unset !important;
  }

  .mdc-checkbox {
    padding: 5px 5px 15px 5px;
    margin: 5px 5px 5px -10px;
  }
}

.success-snackbar .mat-button-wrapper {
  color: greenyellow;
}

.danger-snackbar .mat-button-wrapper {
  color: #f15247;
}

.warning-snackbar .mat-button-wrapper {
  color: #f6e827;
}

h2.titleForm {
  font-size: 15px;
  border-bottom: 1px dashed #b5cad4;
  display: block;
  padding: 10px 0;
  margin: 10px 0 30px 0;
  color: #607d8b;
  font-weight: 300;
}

.btn-fullsize-modal {
  position: absolute !important;
  right: 55px !important;
}

.containerLoadingBar {
  height: 5px;
}

.mat-icon.mat-success {
  color: #2cbd49 !important;
}

.badge {
  padding: 6px 6px !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

/*********** FORMS ****************/

form-nota-debito,
form-nota-credito,
form-boleta,
form-boleta-exenta,
form-factura,
form-factura-exenta,
form-factura-referencias,
form-nota-debito,
form-orden-compra,
form-guia-despacho,
form-automatic-factura,
form-guias-despacho-referencias {
  .content-totales {
    label {
      font-size: 12px !important;
      color: #4c4c4c !important;
      margin-left: 3px !important;
      margin-bottom: 2px !important;
    }

    h1 {
      font-size: 40px !important;
      font-weight: 500 !important;
      margin-top: 5px !important;
      border-bottom: dashed 1px #d2d2d2;
      padding-bottom: 10px;
    }

    h2 {
      font-size: 20px !important;
      font-weight: 400 !important;
      color: #607d8b !important;
      border-bottom: dashed 1px #d2d2d2;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 12px 16px !important;
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover,
  .mat-expansion-panel-header {
    background: #f2f2f2 !important;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 48px !important;
  }

  .mat-expansion-panel-header {
    padding: 0 24px 0 13px !important;

    div {
      width: 100% !important;
      text-align: center !important;
      font-size: 13px !important;
      color: #545454 !important;
      display: flex !important;
      align-items: center !important;
      flex-grow: 3 !important;
    }
  }

  .table {
    font-size: 13px !important;
    color: #2d2d2d !important;
    font-weight: 300 !important;

    .mat-form-field-infix {
      padding: 0 0px 5px 0px !important;
    }

    .mat-form-field-appearance-standard .mat-form-field-flex {
      padding-top: 0 !important;
    }

    .mat-form-field-infix {
      width: 100px !important;
    }

    tbody td {
      vertical-align: middle !important;
    }
  }

  .table > :not(:first-child) {
    border-top: 1px solid #6d6d6d !important;
  }

  .boxDetalle {
    h3 {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #ffffff;
      background: #a0b7be;
      padding: 2px 10px 2px 10px;
      border-radius: 3px;
    }
  }

  .mat-chip.mat-standard-chip {
    background-color: #ffc107 !important;
  }

  .mat-standard-chip {
    border-radius: 5px !important;
  }

  .mat-chip-list-wrapper {
    min-height: 20px !important;
  }

  /*********** PREVIEW ****************/
  .dte_preview {
    border: 1px solid #d8d8d8;
    width: 900px;
    min-width: 216mm;
    margin-top: 20px;
    margin-bottom: 60px;

    .total_preview {
      width: 80%;
      margin: auto;
      padding: 20px 0 20px 20px;
      float: right;
      text-align: right;
    }

    .total_detail {
      text-align: right;
    }

    .company_data {
      margin: 30px 5px 10px 5px;
      text-align: left;

      h5 {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }

    h6 {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .title_tex {
      font-weight: bold;
    }

    .text-red {
      color: #ff0000 !important;
    }

    .sii_preview {
      border: 2px solid #ff0000;
      width: 8cm;
      height: 3cm;
      margin: 20px auto 0 auto;

      h5 {
        font-size: 16px !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
    }

    .client_previe {
      border: 1px solid #d8d8d8;
      width: 100%;
      margin: auto;
      padding: 10px 20px 10px 20px;
      text-transform: uppercase;
    }

    .detail_head {
      border: 1px solid #d8d8d8;
      width: 100%;
      margin: auto;
      padding: 5px;
      text-align: center;
    }

    .detail_body {
      border-bottom: 1px solid #d8d8d8;
      border-left: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      width: 100%;
      min-height: 320px;
      margin: auto;
      padding: 10px;
      text-align: center;
    }

    .grid_colum {
      text-align: center;
    }

    .right_grid_colum {
      text-align: right;
    }

    .left_grid_colum {
      text-align: left;
    }

    .total_text {
      font-weight: 500;
      text-transform: uppercase;
      color: #222;
      font-size: 13px;
    }

    .contentTimbre {
      img {
        width: 100%;
      }

      p {
        font-size: 11px;
        margin: 0px;
        padding: 0;
        text-align: center;
      }
    }

    #footer {
      position: fixed;
      bottom: 0px;
      height: 50px;
      width: 100%;
      padding-left: 15%;
      padding-right: 15%;
      text-align: right;
      background-color: #ffffff;
      border-top: 1px dashed #808080;
    }

    .foot_button {
      margin: 10px;
    }
  }
}

form-orden-compra {
  .dte_preview {
    .text-blue {
      color: #3f51b5 !important;
    }

    .sii_preview {
      border: 2px solid #3f51b5 !important;
    }
  }
}

// Logo y avatar
form-logo,
form-avatar {
  .ngx-ic-cropper {
    outline: rgba(0, 0, 0, 0.6) solid 100vw !important;
  }

  .boxInputfile {
    text-align: center;
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    color: #fff;
    background-color: #00bcd4;
    max-width: 100%;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 5px 0 0;
    width: 120px;
    border-radius: 5px;
  }
}

.loading-overlay {
  height: 4px !important;
  position: absolute !important;
  z-index: 99 !important;
  width: 100% !important;
}

.dropzone-wrapper {
  height: 200px; //   position: absolute; //   left: 50%;
  //   top: 100px;
  //   margin-left: -150px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  font-family: Arial;
  text-align: center;
  position: relative;
  line-height: 180px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);

  &:hover {
    color: #7491ca;
    border: 2px dashed #7491ca;
  }

  .dropzone-input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .file-name {
    color: #48b981;
  }
}

.dropzone-wrapper-error {
  border: 2px dashed #cb3b45;
  color: #cb3b45;
}

.am5-modal-content {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px 0px !important;
}

.ace_editor.ace-jsoneditor {
  min-height: 500px;
}

div.jsoneditor-tree {
  min-height: 400px !important;
}

.jsoneditor-poweredBy {
  display: none;
}

.am5-modal {
  z-index: 200 !important;
}

.icon-btn {
  font-size: 18px;
  margin-top: 2px;
  display: block;
}

.reload-ag-grid-absolute {
  position: absolute !important;
  right: 140px !important;
}

app-sales-received,
dte-purchases-received {
  .card-stats {
    padding: 0;
    margin-bottom: 10px;
  }

  .card {
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.87);
    padding: 10px !important;
  }

  .card-stats .card-header.card-header-icon,
  .card-stats .card-header.card-header-text {
    text-align: right;
  }

  .card-stats .material-icons,
  .card-chart .material-icons {
    margin-top: -2px !important;
  }

  .card .card-header {
    border-bottom: none;
    background: transparent;
    padding: 10px 0;
    z-index: 3 !important;
  }

  .card [class*='card-header-'],
  .card[class*='bg-'] {
    color: #1da4b5;
  }

  .card [class*='card-header-'] {
    margin: 0px 10px 0;
    padding: 0;
    position: relative;
  }

  .card [class*='card-header-'] .card-icon,
  .card [class*='card-header-'] .card-text {
    margin-top: -10px;
    float: left;
  }

  .card-stats .card-header.card-header-icon i {
    font-size: 36px;
    line-height: 56px;
    width: 56px;
    height: 56px;
    text-align: center;
  }

  .card-stats .card-header .card-category:not([class*='text-']) {
    color: #929292;
    font-size: 15px;
    font-weight: 100;
  }

  .card .card-header.card-header-icon .card-title,
  .card .card-header.card-header-text .card-title {
    color: #607d8b !important;
    font-size: 25px !important;
    font-weight: 300 !important;
    margin-top: 0;
  }
}

.mat-drawer-bot {
  background: #fff !important;
  width: 500px !important;
  border-radius: 10px 0px 0px 10px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 1px !important;
  border: 0 !important;

  .mat-drawer-inner-container {
    overflow: hidden !important;
  }
}

.mat-drawer-feedback {
  background: #fff !important;
  width: 500px !important;
  border-radius: 10px 0px 0px 10px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 1px !important;
  border: 0 !important;

  .mat-drawer-inner-container {
    overflow: hidden !important;
  }
}

.btnChat,
.btnFeedback {
  position: fixed !important;
  right: 10px !important;
  bottom: 75px !important;
  z-index: 9 !important;
}

.btnFeedback {
  bottom: 13px !important;
}