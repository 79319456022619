/* You can add global styles to this file, and also import other style files */
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import "animate.css/animate.min.css";

html, body { height: 100%; }
body {
  background-color: #f0f2f5;
  color: #3C4858;
  min-height: 100vh;
  font-family: 'Poppins','Roboto', 'Helvetica', 'Arial', sans-serif!important;
}
