@media (max-width: 1024px) {
  .titleSection {
    margin-bottom: 15px !important;
    margin-top: 19px !important;
    display: block !important;
    width: 100% !important;

    .box {
      display: none !important;
    }

    .contentTitle {
      width: 100%;
      margin-bottom: 20px !important;
    }
  }

  .contentBoxSearch {
    display: inline-block !important;
  }
}

@media (max-width: 700px) {
  .mat-drawer-bot {
    width: 370px !important;
  }

  .mat-drawer-feedback {
    width: 370px !important;
  }

  .titleSection {
    text-align: center;

    h2 {
      text-align: left;
    }

    .box {
      display: block !important;
    }

    .contentTitle {
      width: 100%;
      margin-bottom: 40px !important;
    }
  }

  .contentBoxSearch {
    display: inline-block !important;
    max-width: 300px !important;
    text-align: center !important;
    min-width: 300px !important;
    margin-bottom: 20px !important;
  }

  .data-box {
    .mat-mdc-card-content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .mat-vertical-stepper-header {
    padding: 10px !important;
  }

  .mat-vertical-content-container {
    margin-left: 22px !important;
  }

  .mat-vertical-content {
    padding: 0 10px 10px 10px !important;
  }

  /*********** FORMS ****************/

  form-nota-debito,
  form-nota-credito,
  form-boleta,
  form-boleta-exenta,
  form-factura,
  form-factura-exenta,
  form-factura-referencias,
  form-nota-debito,
  form-orden-compra,
  form-guia-despacho,
  form-automatic-factura,
  form-guias-despacho-referencias {
    .dte_preview {
      border: 1px solid #d8d8d8;
      width: 100% !important;
      min-width: 1px !important;

      .client_previe {
        padding: 10px 0px 10px 0px;
      }

      .total_preview {
        width: 100% !important;
      }
    }
  }
}

/*********** PREVIEW ****************/
.dte_preview {
  @media print {
    #footer {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  .card-stats {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .card-stats:first-child {
    padding-left: 0px !important;
  }

  .card-stats:last-child {
    padding-right: 0px !important;
  }
}
